import axios from "@/plugins/axios";
import authHeader from '../auth-header'
import ConfigService from "../config"


const API_URL = ConfigService.base_url;

class EcommerceSettingServiceApi {
    getProjectDetails() {
        return axios
          .get(API_URL + 'project',
          {
              headers:authHeader()
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
      updateProjectDetails(data) {
        return axios
          .post(API_URL + 'admin/project',data,
          {
              headers:authHeader()
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
      getImportDutiesDetails() {
        return axios
          .get(API_URL + 'importDuties',
          {
              headers:authHeader()
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
      updateImportDutiesDetails(data) {
        return axios
          .post(API_URL + 'importDuties',data,
          {
              headers:authHeader()
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
  
}



export default new EcommerceSettingServiceApi()