import axios from "@/plugins/axios";
import authHeader from '../auth-header'
import ConfigService from "../config"

const API_URL = ConfigService.base_url;

class ShippingFeeServiceApi {
    getAllFee() {
        return axios
          .get(API_URL + 'country',       { headers:authHeader()})
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
      shppingFeeAction(data) {
        return axios
          .post(API_URL + 'country', 
            data,  
            { headers:authHeader()})
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
  
}



export default new ShippingFeeServiceApi()