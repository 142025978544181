<script>
import Layout from "../../layouts/main";
import EcommerceSettingServiceApi from "@/service/api/ecommerce-settings";
import ShippingFeeServiceApi from "@/service/api/shipping-fee";
import Countries from "@/service/country";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelSelect } from "vue-search-select";
import Spinner from "@/components/spinner";

export default {
  components: {
    Layout,
    ModelSelect,
    Spinner,
  },
  data() {
    return {
      project: {
        shipping_fee: 0,
        transaction_fee: 0,
        transaction_percent: 0,
        processing_percent: 0,
        import_percent:0
      },
      import_duties: {
        admin_fee_gt: 0,
        admin_fee_lt: 0,
        import_shipping_rate:0,
        export_shipping_rate:0,
        emergency_surcharge:0,
        fuel_surcharge:0
      },
      editProject: true,
      editImportDuties: true,
      country_shipping_fee: [],
      editCountryFee: {
        name: null,
        shipping_fee: null,
        id: null,
      },
      editCountryFeeSubmit: false,
      currentPage: 1,
      perPage: 12,
      pageOptions: [12, 24, 50, 100],
      loadingCountryData: false,
      buttonAction: {
        adding: false,
        updating: false,
        deleting: false,
      },
    };
  },
  computed: {
    countryDataList() {
      return Countries.map((item) => {
        return { value: item.name, text: item.name };
      });
    },
    rows() {
      return this.country_shipping_fee ? this.country_shipping_fee.length : 0;
    },
    countryDataFiltered() {
      let startIndex = (this.currentPage - 1) * this.perPage;
      let endIndex = startIndex + this.perPage;
      return this.country_shipping_fee.slice(startIndex, endIndex);
    },
  },
  mounted() {
    this.loadProjectDetails();
    this.loadImportDuties();
  },
  methods: {
    async loadProjectDetails() {
      EcommerceSettingServiceApi.getProjectDetails().then((response) => {
        this.project = response.data;
      });
    },
    updateProject() {
      let data = this.project;
      for (const [, value] of Object.entries(this.project)) {
        if (parseFloat(value) < 0) return;
        
      }
  
      if(data.transaction_percent >100 || data.processing_percent>100 || data.import_percent>100)
        return;
      EcommerceSettingServiceApi.updateProjectDetails(data).then((response) => {
        if (response.data.success) {
          this.editProject = true;
          this.loadProjectDetails();
          this.projectActionMessage(
            "Project Update",
            "Project updated successfully!"
          );
        } else {
          this.projectActionMessage(
            "Project Update",
            "Project updated failed!",
            "danger"
          );
        }
      });
    },
    loadAllShippingFee() {
      this.loadingCountryData = true;
      ShippingFeeServiceApi.getAllFee()
        .then((response) => {
          this.country_shipping_fee = response.data;
        })
        .finally(() => {
          this.loadingCountryData = false;
        });
    },
    loadImportDuties(){
       EcommerceSettingServiceApi.getImportDutiesDetails().then(res=>{
         this.import_duties = res.data;
       })
    },
    projectActionMessage(title, message, variant = "success") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    openShippingFeeModal() {
      this.$refs["countryShipping"].show();
    },
    tabChanged(val) {
      if (val === 1) {
        this.loadAllShippingFee();
      }
    },
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
    async shippingFeeAction(data) {
      let subData = {};
      if (data.action === "delete") {
        subData.id = data.id;
        subData.action = data.action;
        this.buttonAction.deleting = true;
      } else {
        if (data.id) {
          this.buttonAction.updating = true;
          subData.id = data.id;
          subData.action = data.action;
          subData.name = data.name;
          subData.shipping_fee = data.shipping_fee;
        } else {
          this.buttonAction.adding = true;
          subData.action = data.action;
          subData.name = data.name;
          subData.shipping_fee = data.shipping_fee;
        }
      }

      ShippingFeeServiceApi.shppingFeeAction(subData).then((response) => {
        if (response.data.success) {
          this.$refs["countryShipping"].hide();
          this.loadAllShippingFee();
          this.resetEditCountryFee();

          if (data.action === "add") {
            this.buttonAction.adding = false;
            this.shippingFeeActionSuccess(response.data.country.name, "added");
          } else if (data.action === "edit") {
            this.buttonAction.updating = false;
            this.shippingFeeActionSuccess(
              response.data.country.name,
              "updated"
            );
          } else {
            this.buttonAction.deleting = false;
            this.shippingFeeActionSuccess(data.name, "deleted");
          }
        } else {
          if (data.action === "add") {
            this.shippingFeeActionFailed(response.data.error, "add");
          }
        }
      });
    },
    resetEditCountryFee() {
      this.editCountryFee.name = null;
      this.editCountryFee.shipping_fee = null;
      this.editCountryFee.id = null;
      this.editCountryFeeSubmit = false;
    },
    submitCountryFeeForm(action = "add") {
      this.editCountryFeeSubmit = true;
      if (
        this.editCountryFee.name &&
        this.editCountryFee.shipping_fee &&
        this.editCountryFee.shipping_fee >= 0
      ) {
        this.shippingFeeAction({
          action: action,
          id: this.editCountryFee.id,
          name: this.editCountryFee.name,
          shipping_fee: this.editCountryFee.shipping_fee,
        });
      }
    },
    shippingFeeActionSuccess(country, action = "added") {
      this.$bvToast.toast(`Shipping Fee ${action} for ${country}! `, {
        title: `Country fee ${action}!`,
        variant: "success",
        solid: true,
      });
    },
    shippingFeeActionFailed(error, action = "add") {
      this.$bvToast.toast(
        `Shipping Fee ${action} failed with, ${error} error`,
        {
          title: `Country fee ${action}!`,
          variant: "danger",
          solid: true,
        }
      );
    },
    countrySelect(shipping_fee) {
      this.editCountryFee = { ...shipping_fee };
      this.$refs["countryShipping"].show();
    },
    updateImportDuties(){
      
      if(this.import_duties.fuel_surcharge >100 )
        return;
      EcommerceSettingServiceApi.updateImportDutiesDetails(this.import_duties).then(res=>{
        if(res.data.success){
          console.log(res.data.import)
           this.editImportDuties = true;
           this.projectActionMessage(
            "Import Duties Update",
            "Import Duties updated successfully!")
        }
          else{
              this.projectActionMessage(
            "Import Duties Update",
            "Import Duties updation failed!",
            "danger")
          }
      })

    }
  },
};
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-details">
        <b-tabs @activate-tab="tabChanged">
          <b-tab title="General">
            <div class="m-4" style="font-size:14px">
              <FormulateInput
                type="number"
                v-model="project.shipping_fee"
                name="shipping fee"
                label="Shipping Fee (AED)"
                placeholder="Shipping fee here!"
                validation="required|min:0"
                :disabled="editProject"
              />

              <FormulateInput
                type="number"
                v-model="project.transaction_fee"
                name="Trade Fee"
                label="Trade Fee (AED)"
                placeholder="Trade fee here!"
                validation="required|min:0"
                :disabled="editProject"
              />

              <FormulateInput
                type="number"
                v-model="project.transaction_percent"
                name="transaction percent"
                label="Transaction Percent (%)"
                placeholder="Transaction percent here!"
                validation="required|min:0|max:100"
                :disabled="editProject"
              />

              <FormulateInput
                v-model="project.processing_percent"
                type="number"
                name="processing percent"
                label="Processing Percent (%)"
                placeholder="Processing percent here!"
                validation="required|min:0|max:100"
                :disabled="editProject"
              />

               <FormulateInput
                v-model="project.import_percent"
                type="number"
                name="Import Percent"
                label="Import Percent (%)"
                placeholder="Import percent here!"
                validation="required|min:0|max:100"
                :disabled="editProject"
              />
              <div style="padding-bottom:50px;">
                <div class="mt-4 text-center">
                  <button
                    class="btn btn-warning w-md waves-effect waves-light float-left"
                    type="button"
                    v-if="!this.editProject"
                    @click="updateProject"
                  >
                    Update
                  </button>

                  <button
                    class="btn btn-primary w-md waves-effect waves-light float-right"
                    type="button"
                    @click="() => (this.editProject = false)"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </b-tab>
           <b-tab title="Import Duties">
            <div class="m-4" style="font-size:14px">
              <FormulateInput
                type="number"
                v-model="import_duties.import_shipping_rate"
                name="shipping fee"
                label="Import Shipping Rate (AED)"
                placeholder="Import Shipping Rate here"
                validation="required|min:0"
                :disabled="editImportDuties"
              />
              <FormulateInput
                type="number"
                v-model="import_duties.emergency_surcharge"
                name="Emergency Surcharge"
                label="Emergency Surcharge (AED)"
                placeholder="Emergency Surcharge (AED)"
                validation="required|min:0"
                :disabled="editImportDuties"
              />
              <FormulateInput
                type="number"
                v-model="import_duties.fuel_surcharge"
                name="Fuel Surcharge"
                label="Fuel Surcharge (%)"
                placeholder="Fuel Surcharge  here"
                validation="required|min:0|max:100"
                :disabled="editImportDuties"
              />
              <FormulateInput
                type="number"
                v-model="import_duties.admin_fee_gt"
                name="Admin Fee"
                label="Admin Fee (Fee greater than 1000) in AED"
                placeholder="Admin Fee here"
                validation="required|min:0"
                :disabled="editImportDuties"
              />
                <FormulateInput
                type="number"
                v-model="import_duties.admin_fee_lt"
                name="Admin Fee"
                label="Admin Fee ( Less than 1000) in AED"
                placeholder="Admin Fee ( Less than 1000) here"
                validation="required|min:0"
                :disabled="editImportDuties"
              />    
              <div style="padding-bottom:50px;">
                <div class="mt-4 text-center">
                  <button
                    class="btn btn-warning w-md waves-effect waves-light float-left"
                    type="button"
                    v-if="!this.editImportDuties"
                    @click="updateImportDuties"
                  >
                    Update
                  </button>

                  <button
                    class="btn btn-primary w-md waves-effect waves-light float-right"
                    type="button"
                    @click="() => (this.editImportDuties = false)"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Country">
            <div class="row" style="padding-top:10px; margin:8px">
              <div class="col-sm-12 col-md-6">
                <div>
                  <label class="d-inline-flex align-items-center">
                    <span style="margin-right:10px">
                      Show
                    </span>

                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                    <button
                      @click="loadAllShippingFee"
                      class="btn btn-sm btn-secondary ml-1"
                    >
                      <i class="ri-refresh-line"></i>
                    </button>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <button
                  class="btn btn-danger float-right"
                  @click="openShippingFeeModal"
                >
                  Add Country
                </button>
              </div>
              <!-- End search -->
            </div>

            <div class="clearfix"></div>
            <div v-if="loadingCountryData">
              <Spinner :show="loadingCountryData" />
            </div>
            <div v-else>
              <div class="row">
                <div
                  class="col-sm-4"
                  v-for="shipping_fee in countryDataFiltered"
                  :key="shipping_fee.id"
                >
                  <div class="countryCard" @click="countrySelect(shipping_fee)">
                    <div class="row">
                      <div class="col-5 countryCard-ct">
                        Country
                      </div>
                      <div class="col-7">
                        {{ shipping_fee.name }}
                      </div>
                    </div>
                    <hr />
                    <div class="row mt-3">
                      <div class="col-5 countryCard-ct">
                        Fee
                      </div>
                      <div class="col-7">
                        AED {{ shipping_fee.shipping_fee }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-5 mr-1 mt-5">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <b-modal
              ref="countryShipping"
              id="countryShipping"
              centered
              title="Shipping based on Country "
              hide-footer
              @hidden="resetEditCountryFee"
            >
              <div style="margin-bottom:20px">
                <div style="font-weight:900;color:#55626e;s;font-size:0.98rem">
                  Country
                </div>
                <model-select
                  :options="countryDataList"
                  placeholder="Select
                Country"
                  v-model="editCountryFee.name"
                />
                <div
                  class="shipping-field-error"
                  v-if="this.editCountryFeeSubmit && !this.editCountryFee.name"
                >
                  Country field is required!
                </div>
                <FormulateInput
                  type="number"
                  name="shipping fee"
                  label="Shipping Fee"
                  placeholder="Shipping fee here!"
                  validation="min:0"
                  v-model="editCountryFee.shipping_fee"
                />
                <div
                  class="shipping-field-error"
                  v-if="
                    this.editCountryFeeSubmit &&
                      !this.editCountryFee.shipping_fee
                  "
                >
                  Shipping Fee is required!
                </div>
                <button
                  class="btn btn-danger mt-4"
                  style="width:100px"
                  @click="submitCountryFeeForm('delete')"
                  v-if="editCountryFee.id"
                >
                  <span v-if="buttonAction.deleting">Deleting...</span>
                  <span v-else>Delete</span>
                </button>
                <button
                  class="btn btn-primary float-right mt-4"
                  style="width:100px"
                  @click="submitCountryFeeForm('add')"
                  v-if="!editCountryFee.id"
                >
                  <span v-if="buttonAction.adding">Adding...</span>
                  <span v-else>Add</span>
                </button>
                <button
                  class="btn btn-warning float-right mt-4"
                  style="width:100px"
                  @click="submitCountryFeeForm('edit')"
                  v-else
                >
                  <span v-if="buttonAction.updating">
                    Updating...
                  </span>
                  <span v-else>Update</span>
                </button>
              </div>
            </b-modal>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </Layout>
</template>

<style>
.countryCard {
  margin: 10px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 20px 20px;
  border-radius: 5px;
}

/* On mouse-over, add a deeper shadow */
.countryCard:hover {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.countryCard-ct {
  font-weight: 900;
  color: #ff3d60;
}

#countryShipping .ui.selection.dropdown {
  background-color: #f1f1f1;
  outline: none;
  border: none;
}
.shipping-field-error {
  color: #960505;
  font-size: 12px;
  font-weight: 100;
}

#countryShipping .formulate-input {
  margin-bottom: 0;
}
</style>
